<template>
  <v-app>
    <v-main>
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="8" md="4">
            <v-card class="elevation-10">
              <v-toolbar
                :color="tab == 2 ? 'indigo darken-1' : 'teal lighten-3'"
                dark
                flat
              >
                <v-toolbar-title class="headline">
                  <v-avatar left>
                    <v-img src="@/assets/logo.png" />
                  </v-avatar>
                  Web受発注管理ログイン
                </v-toolbar-title>

                <template v-slot:extension> </template>
              </v-toolbar>

              <v-form v-model="valid" ref="form" @submit.prevent="login">
                <v-card-text class="mt-5">
                  <v-text-field
                    v-model="id"
                    class="mx-5"
                    label="ログインID"
                    prepend-inner-icon="mdi-account"
                    outlined
                    rounded
                    :rules="[Rules.Required]"
                  ></v-text-field>

                  <v-text-field
                    v-model="password"
                    class="mx-5"
                    label="パスワード"
                    prepend-inner-icon="mdi-lock"
                    :append-icon="!eye ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="eye ? 'text' : 'password'"
                    outlined
                    rounded
                    :rules="[Rules.Required]"
                    @click:append="eye = !eye"
                  ></v-text-field>
                  <p class="text-h8 black--text" style="text-align: right">
                    {{ version }}
                  </p>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn type="login" color="primary" text>
                    ログイン
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Forms from "@/mixins/forms";
import ShowDialogs from "@/mixins/showDialogs";
// import QrDialog from "./dialogs/QrDialog.vue";

import Login from "@/models/Login";
import Api from "@/mixins/api";

async function sleep(ms) {
  await new Promise(resolve => setTimeout(resolve, ms));
}

export default {
  name: "Login",
  mixins: [Forms, ShowDialogs, Api],
  data() {
    return {
      id: "",
      password: "",
      isQrRead: false,
      eye: false,
      tab: 1
    };
  },
  computed: {
    version: {
      get() {
        return `Ver:${process.env.VUE_APP_VERSION}`;
      }
    }
  },
  methods: {
    async login() {
      if (!this.$refs.form.validate()) return;

      let result = false;

      this.$loading();
      try {
        const payload = new Login(
          this.id,
          this.password,
          this.tab,
          this.isQrRead
        );

        await sleep(2000);
        const resp = await this.$auth(payload);

        result = await this.$store.commit("login", resp);
        console.log(result);

        let authority = this.$store.state.authority;
        if (authority == undefined || authority == null || authority === "") {
          authority = "0";
        }

        // if (this.tab == 0) {
        //   this.$router.push("/office");
        // } else if (this.tab == 1) {
        //   if (authority === "5") {
        //     this.$router.push("/list/jyutyuInput");
        //   } else {
        //     if (authority === "0") {
        //       this.$router.push("/user");
        //     } else {
        //       this.$router.push("/attendance");
        //     }
        //   }
        // } else if (this.tab == 2) {
        //   this.$router.push("/user");
        // }
        //this.$router.push("/office");
        if (authority === "1") {
          this.$router.push("/office");
        } else {
          this.$router.push("/office");
        }
      } catch (e) {
        this.$error(e.message);
      } finally {
        this.$unloading();
      }
    }
  }
};
</script>

<style></style>
